import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/fund-asset-managers/*" />
    <RAC path="/zh-cn/fund-asset-managers/*" />
    <RAC path="/zh-tw/fund-asset-managers/*" />
    <RAC path="/pl/fund-asset-managers/*" />
    <RAC path="/ru/fund-asset-managers/*" />
    <RAC path="/it/fund-asset-managers/*" />
    <RAC path="/de/fund-asset-managers/*" />
    <RAC path="/es/fund-asset-managers/*" />
    <RAC path="/fr/fund-asset-managers/*" />
    <RAC path="/ar/fund-asset-managers/*" />
    <RAC path="/id/fund-asset-managers/*" />
    <RAC path="/pt/fund-asset-managers/*" />
    <RAC path="/ko/fund-asset-managers/*" />
    <DynamicNotFoundPage default />
  </Router>
)
